import React, { useEffect, useState } from "react"
import * as styles from "./intro.module.css"
import Layaut from "./layout"
import { intro } from "utils/data"
import api from "apis"
import pcViewerBackground from "images/pcViewerBackground.png"
import dompurify from "dompurify"
import { navigate } from "gatsby"

const Intro = () => {
  const [body, setBody] = useState("")
  useEffect(() => {
    ;(async () => {
      const result = await api.getIntro()
      console.log(result)
      setBody(result)
    })()
  }, [])

  return (
    <Layaut menu={Array.from(intro)}>
      <div className={styles.textbox}>
        {typeof window !== "undefined" ? (
          <div dangerouslySetInnerHTML={{ __html: dompurify.sanitize(body) }} />
        ) : null}
      </div>
      <div className={styles.con}>
        <button
          className={styles.loginBox}
          style={{
            marginTop: "0px",
          }}
          onClick={() => navigate("/subscription")}
        >
          피치서가 정기구독하기
        </button>
      </div>
    </Layaut>
  )
}

export default Intro
